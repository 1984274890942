import Decimal from 'decimal.js-light'
import { getRoundedNumber } from '@/utils/numbers'

function calculateVoucherValuePerUnit(sellingPrice, units) {
  //Total Gross Price / No. Individual Vouchers
  if (units <= 0) return null
  const _sellingPrice = new Decimal(sellingPrice || 0)
  const vouchers = new Decimal(units)
  return getRoundedNumber(_sellingPrice.div(vouchers))
}
function calculateDiscountValuePerUnit(sellingPrice, totalPrice, units) {
  //(Face Value - Total Gross Price) / No. Individual Vouchers
  //( €125 - €100 ) / 5 = €5
  if (units <= 0) return null
  const _totalPrice = new Decimal(totalPrice || 0)
  const _sellingPrice = new Decimal(sellingPrice || 0)
  const vouchers = new Decimal(units)
  return getRoundedNumber(_totalPrice.minus(_sellingPrice).div(vouchers))
}
function calculateDiscountValue(sellingPrice, totalValue) {
  //Product Gross Price - selling price
  // €25 - €20 = €5
  const _sellingPrice = new Decimal(sellingPrice || 0)
  const _totalValue = new Decimal(totalValue || 0)
  return getRoundedNumber(_totalValue.minus(_sellingPrice))
}

export function onSellingPrice(voucher, sellingPrice) {
  const _sellingPrice = getRoundedNumber(new Decimal(sellingPrice || 0))
  const _voucherValuePerUnit = calculateVoucherValuePerUnit(
    _sellingPrice,
    voucher.units
  )
  const _discountValuePerUnit = calculateDiscountValuePerUnit(
    _sellingPrice,
    voucher.total_value,
    voucher.units
  )
  const _discountValue = calculateDiscountValue(
    _sellingPrice,
    voucher.total_value
  )

  return {
    selling_price: _sellingPrice,
    voucher_value_per_unit: _voucherValuePerUnit,
    discount_value_per_unit: _discountValuePerUnit,
    discount_value: _discountValue
  }
}

export function onVoucherValuePerUnit(voucher, valuePerUnit) {
  const _voucherValuePerUnit = new Decimal(valuePerUnit || 0)

  //calculate selling price from multiplying the number of units with selling value per unit.
  const _sellingPrice = getRoundedNumber(
    new Decimal(voucher.units).mul(_voucherValuePerUnit)
  )
  const _discountValuePerUnit = calculateDiscountValuePerUnit(
    _sellingPrice,
    voucher.total_value,
    voucher.units
  )
  const _discountValue = calculateDiscountValue(
    _sellingPrice,
    voucher.total_value
  )
  return {
    selling_price: _sellingPrice,
    voucher_value_per_unit: getRoundedNumber(_voucherValuePerUnit),
    discount_value_per_unit: _discountValuePerUnit,
    discount_value: _discountValue
  }
}

export function onDiscountValuePerUnit(voucher, discountValuePerUnit) {
  const _discountValuePerUnit = new Decimal(discountValuePerUnit || 0)

  //calculating total discount value from discount value per unit
  //multiplying number of units with discount value per unit
  const _discountValue = new Decimal(voucher.units).mul(_discountValuePerUnit)

  //calculate selling price from total value, by reducing the total discount value from total value (face value).
  const _sellingPrice = getRoundedNumber(
    new Decimal(voucher.total_value).minus(_discountValue)
  )
  const _voucherValuePerUnit = calculateVoucherValuePerUnit(
    _sellingPrice,
    voucher.units
  )

  return {
    selling_price: _sellingPrice,
    voucher_value_per_unit: _voucherValuePerUnit,
    discount_value_per_unit: getRoundedNumber(_discountValuePerUnit),
    discount_value: getRoundedNumber(_discountValue)
  }
}

export function onDiscountValue(voucher, discountValue) {
  const _discountValue = new Decimal(discountValue || 0)
  const _sellingPrice = getRoundedNumber(
    new Decimal(voucher.total_value || 0).minus(_discountValue)
  )
  const _voucherValuePerUnit = calculateVoucherValuePerUnit(
    _sellingPrice,
    voucher.units
  )
  const _discountValuePerUnit = calculateDiscountValuePerUnit(
    _sellingPrice,
    voucher.total_value,
    voucher.units
  )
  return {
    selling_price: _sellingPrice,
    voucher_value_per_unit: _voucherValuePerUnit,
    discount_value_per_unit: _discountValuePerUnit,
    discount_value: getRoundedNumber(_discountValue)
  }
}

export function onTotalValue(voucher, totalValue) {
  //calculate total discount value from total
  const _discountValue = new Decimal(voucher.discount_value || 0)

  //calculate selling price from reducing total discount value from total value (face value).
  const _sellingPrice = getRoundedNumber(
    new Decimal(totalValue || 0).minus(_discountValue)
  )
  const _voucherValuePerUnit = calculateVoucherValuePerUnit(
    _sellingPrice,
    voucher.units
  )
  const _discountValuePerUnit = calculateDiscountValuePerUnit(
    _sellingPrice,
    totalValue,
    voucher.units
  )

  return {
    selling_price: _sellingPrice,
    voucher_value_per_unit: _voucherValuePerUnit,
    discount_value_per_unit: _discountValuePerUnit,
    discount_value: getRoundedNumber(_discountValue)
  }
}

export function parsePathToDotChaining(path = '') {
  //a path in string format, like: 'default_prices[0]prices[1]'
  //we replace the [] with dots for safeGet
  //example:'default_prices[0]prices[1]' => 'default_prices.0.prices.1'
  return path
    .replace(/\[|\](?!$)/g, '.') //change all [ or ] with dot, except the last closing bracket
    .replace(/\]/, '') //remove the last closing bracket if present
    .replace(/\.+/g, '.') //replace .. to .
}
