<template>
  <th-wrapper
    :title="$t('common.titles.general_info.title')"
    :subtitle="$t('pages.abocard_systems.edit.card_information.subtitle')"
  >
    <el-form ref="form" :model="model" :rules="rules" class="">
      <el-row>
        <!-- Selling Price Method -->
        <el-form-item prop="type">
          <el-radio-group v-model="model.selling_price_method" size="large">
            <div class="flex">
              <el-radio label="dynamic">
                {{
                  $t(
                    'pages.abocard_systems.edit.card_information.selling_price_method.values.dynamic'
                  )
                }}
              </el-radio>
              <el-radio label="static">
                {{
                  $t(
                    'pages.abocard_systems.edit.card_information.selling_price_method.values.static'
                  )
                }}
              </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
      </el-row>
      <el-row :gutter="20">
        <!-- name -->
        <el-col :lg="6" :md="12">
          <el-form-item
            :label="$t('pages.abocard_systems.edit.card_information.name')"
            prop="name"
          >
            <el-input id="name" v-model="model.name" />
          </el-form-item>
        </el-col>

        <!-- locations -->
        <el-col :lg="6" :sm="24">
          <el-form-item
            for="locations"
            :label="
              $t('pages.products.edit.form.properties.available_in.label')
            "
          >
            <available-in
              id="locations"
              :model-value="{
                locations: model.locations,
                branch_groups: model.location_groups
              }"
              :resources="resources"
              :show-items-limit="2"
              @update:modelValue="handleAvailableInInput"
            />
          </el-form-item>
        </el-col>

        <!-- valid until -->
        <el-col :lg="6" :sm="24">
          <el-form-item
            :label="
              $t('pages.abocard_systems.edit.card_information.validity_period')
            "
            prop="validity_period"
          >
            <valid-to
              id="validity_period"
              v-model="model.validity_period.value"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- linked_product -->
        <el-col :lg="6" :sm="24">
          <el-form-item
            :label="
              $t('pages.abocard_systems.edit.card_information.linked_product')
            "
            prop="linked_product"
          >
            <remote-search-select
              id="linked_product"
              ref="linkedProductSelect"
              v-model="model.linked_product"
              class="w-full"
              popper-append-to-body
              resource="products"
              fetch-handler="getAll"
              :computed-fields="['custom_id', 'name']"
              :disabled="!isNew"
              :modify-query="
                (q) => ({
                  query: {
                    limit: 50,
                    deleted: false,
                    q
                  }
                })
              "
              @resource-set="handleLinkedProductSelect"
            />
          </el-form-item>
        </el-col>

        <!-- Revenue account -->
        <el-col :lg="6" :sm="24">
          <!-- Revenue account -->
          <el-form-item
            :label="
              $t(
                'pages.abocard_systems.edit.card_information.system_revenue_account'
              )
            "
            prop="system_revenue_account"
          >
            <el-select
              id="system_revenue_account"
              v-model="model.system_revenue_account"
              v-cancel-read-only
              filterable
              clearable
              class="w-full"
              :placeholder="$t('common.interactions.buttons.select')"
            >
              <el-option
                v-for="item in resources.accounts"
                :key="item.id"
                :label="item.computed_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <!-- price -->
        <el-col v-show="!isDynamic" :lg="6" :sm="24">
          <el-form-item
            :label="$t('pages.abocard_systems.edit.card_information.price')"
            prop="price_id"
          >
            <el-select
              id="price_id"
              v-model="model.price_id"
              v-cancel-read-only
              filterable
              clearable
              class="w-full"
              :disabled="!priceOptions.length"
              :placeholder="$t('common.interactions.buttons.select')"
              @change="handlePriceIdSelect"
            >
              <el-option
                v-for="item in priceOptions"
                :key="item.id"
                :label="selectedPriceLabel"
                :value="item.id"
              >
                <div class="flex justify-between">
                  <div class="mr-4" v-text="item.label" />
                  <div
                    :class="[
                      item.id === model.price_id
                        ? 'text-th-secondary'
                        : 'text-th-light-gray'
                    ]"
                    v-text="item.priceLabel"
                  />
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- Require customer at checkout-->
      <el-checkbox
        v-model="model.requires_customer"
        :label="
          $t('pages.abocard_systems.edit.card_information.requires_customer')
        "
      />
    </el-form>
  </th-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import safeGet from 'just-safe-get'
import * as helpers from './helpers'
import RemoteSearchSelect from '@/components/select/remote-search'
import AvailableIn from '@/components/available-in'
import ValidTo from '@/components/select/valid-to'

export default {
  components: {
    RemoteSearchSelect,
    AvailableIn,
    ValidTo
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    original: {
      type: Object,
      default: () => ({})
    },
    isNew: {
      type: Boolean,
      default: false
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      linkedProduct: null
    }
  },

  computed: {
    ...mapGetters({
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      currentLocation: 'Config/getCurrentLocation'
    }),
    model: {
      get() {
        return this.modelValue || {}
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    linkedProductChanged() {
      return this.model.linked_product !== this.original.linked_product //current vs. original
    },
    rules() {
      return {
        name: {
          required: true,
          message: this.$t('common.forms.rules.field_warnings.required'),
          trigger: ['blur', 'change']
        },
        locations: {
          required: true,
          message: this.$t('common.forms.rules.field_warnings.required'),
          trigger: ['blur', 'change']
        },
        validity_period: {
          required: true,
          message: this.$t('common.forms.rules.field_warnings.required'),
          trigger: ['blur', 'change']
        },
        linked_product: {
          required: true,
          message: this.$t('common.forms.rules.field_warnings.required'),
          trigger: ['blur', 'change']
        },
        price_id: {
          required: !this.isDynamic,
          message: this.$t('common.forms.rules.field_warnings.required'),
          trigger: ['blur', 'change']
        },
        system_revenue_account: {
          required: true,
          message: this.$t('common.forms.rules.field_warnings.required'),
          trigger: ['blur', 'change']
        }
      }
    },
    priceOptions() {
      const { default_prices = [], branch_prices = [] } = safeGet(
        this.linkedProduct,
        'prices',
        {}
      )
      const priceOptions = []
      default_prices.forEach((price, index) => {
        priceOptions.push({
          id: `default_prices[${index}]`,
          label: this.$t('pages.branch_groups.edit.branches_selection.title'),
          priceLabel: this.getPriceLabel(price)
        })
      })
      branch_prices.forEach(({ branch, prices }, branchIndex) => {
        prices.forEach((price, priceIndex) => {
          if (!this.currentLocation || this.currentLocation === branch) {
            priceOptions.push({
              id: `branch_prices[${branchIndex}]prices[${priceIndex}]`,
              label: this.parseBranch(branch),
              priceLabel: this.getPriceLabel(price)
            })
          }
        })
      })
      return priceOptions
    },
    selectedPriceLabel() {
      return this.$formatCurrency(
        this.model.used_product_price,
        this.defaultCurrency
      )
    },
    isDynamic() {
      return this.model.selling_price_method === 'dynamic'
    }
  },

  watch: {
    'model.selling_price_method'() {
      // We need to set or unset when we are changing dynamic/static
      this.model.discount_type = this.isDynamic
        ? this.model.discount_type || 'relative'
        : null
    }
  },

  methods: {
    getPriceLabel(price) {
      const {
        amount: { gross },
        currency
      } = price
      return this.$formatCurrency(gross, currency)
    },
    parseBranch(branchId) {
      if (!branchId) return '-'
      const branch = this.resources.branches?.find(
        (item) => item.id === branchId
      )
      if (!branch) return '-'
      return this.$formatBranch(branch)
    },
    handleAvailableInInput({ locations, branch_groups }) {
      this.model.locations = locations
      this.model.location_groups = branch_groups
    },
    handleLinkedProductSelect(product) {
      this.linkedProduct = product
      //if a product has a changed, reset price_id and total_price
      if (this.linkedProductChanged) {
        this.model.price_id = null
        this.$emit('reset-abocard-calculation')
      }
    },
    handlePriceIdSelect(path) {
      const newPrice = safeGet(
        this.linkedProduct,
        helpers.parsePathToDotChaining(`prices.${path}.amount.gross`),
        null
      )
      this.model.used_product_price = newPrice
      this.model.price_id = path
    },
    // ----------------- Validate -----------------
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
