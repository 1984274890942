<template>
  <th-page-wrapper v-loading="loading">
    <abocard-information
      ref="abocardInformation"
      v-model="current"
      :is-new="isNew"
      :original="original"
      :resources="resources"
      @reset-abocard-calculation="resetAbocardCalculation()"
    />
    <abocard-calculation-dynamic
      v-if="isDynamic"
      ref="abocardCalculationDynamic"
      v-model="current"
      :resources="resources"
    />
    <abocard-calculation-static
      v-else
      ref="abocardCalculationStatic"
      v-model="current"
      :resources="resources"
    />
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import abocardModel from '../model/abocard-model'
import AbocardInformation from './abocard-information'
import AbocardCalculationStatic from './abocard-calculation-static'
import AbocardCalculationDynamic from './abocard-calculation-dynamic'
import { mapGetters } from 'vuex'

function generateDefault() {
  return {
    discount_value_per_unit: null,
    discount_type: 'relative',
    discount_value: null,
    linked_product: null,
    location_groups: null,
    locations: null,
    name: null,
    price_id: null,
    price_source: 'product',
    requires_customer: false,
    selling_price: null,
    system_revenue_account: null,
    system_tax_account: null,
    system_type: 'voucher',
    total_value: null,
    units: null,
    used_product_price: null,
    validity_period: { unit: 'years', value: 1 }, //value 1 by default
    voucher_system: null,
    voucher_value_per_unit: null,
    selling_price_method: 'static'
  }
}
export default {
  components: {
    AbocardInformation,
    AbocardCalculationStatic,
    AbocardCalculationDynamic
  },
  setup() {
    return abocardModel.setup(generateDefault())
  },
  data() {
    return {
      resourceLoading: false,
      resources: {},
      linkedProduct: null
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation',
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    loading() {
      return this.resourceLoading || this.loading
    },
    isDynamic() {
      return this.current.selling_price_method === 'dynamic'
    }
  },
  watch: {
    currentLocation: 'setCurrentLocation'
  },
  async mounted() {
    this.id = this.$route.params.id
    await this.fetch()
    await this.fetchResources()
    //this is a hack. the remote-search-select is rendering too quickly before the product value is passed.
    //there for we trigger `fetchInitialValue` function with refs chaining.
    //TODO: should be removed once we refactor the remote-search-select.
    this.$refs.abocardInformation.$refs.linkedProductSelect.fetchInitialValue()
  },
  methods: {
    async fetchResources() {
      this.resourceLoading = true
      try {
        const {
          branchesV1 = [],
          branchGroups = [],
          accounts = []
        } = await this.$resourceFetch('branchesV1', 'branchGroups', {
          resource: 'accounts',
          handler: () =>
            th.accounts().getAll({ type: 'revenue', deleted: false })
        })
        this.resources = { branches: branchesV1, branchGroups, accounts }
        // Create computed name for accounts
        this.resources.accounts = this.resources.accounts.map((item) => {
          return {
            ...item,
            computed_name: `${item.fa_account_number || ''} - ${
              item.name
            }`.trim()
          }
        })
        this.setCurrentLocation()
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.resourceLoading = false
      }
    },
    setCurrentLocation() {
      if (this.isNew && !this.current.locations) {
        this.current.locations = this.currentLocation
          ? [this.currentLocation]
          : null
      }
    },
    async handleSubmit() {
      let formIsValid = await this.$refs.abocardInformation.validate()
      if (this.isDynamic) {
        formIsValid =
          formIsValid && (await this.$refs.abocardCalculationDynamic.validate())
      } else {
        formIsValid =
          formIsValid && (await this.$refs.abocardCalculationStatic.validate())
      }

      this.cleanAbocard()

      if (!formIsValid) {
        return this.$message({
          type: 'warning',
          title: this.$t('common.forms.warning.invalid_input.title'),
          message: this.$t('common.forms.warning.invalid_input.message')
        })
      }

      const isNew = this.isNew
      const resource = this.$t('common.resource.abocard_system.singular')
      const { error } = await this.save()
      if (error) {
        //save fail
        const errorMessage = isNew
          ? this.$t('common.error.action.create.single')
          : this.$t('common.error.action.update.single')

        this.$logException(error, { message: errorMessage })
        return
      }

      //save success
      const successMessage = isNew
        ? this.$t('common.success.action.create.single')
        : this.$t('common.success.action.update.single')
      this.$message({
        type: 'success',
        message: successMessage
      })

      if (this.navigationAfterCreation === 'edit' || !isNew) {
        this.$router.push({
          name: 'abocard-systems-edit',
          params: { id: this.id }
        })
      } else {
        this.$router.push({ name: 'abocard-systems-list' })
      }
    },
    // Replace with null values that are not meant for the type
    cleanAbocard() {
      if (this.isDynamic) {
        this.current.selling_price = null
        this.current.total_value = null
        this.current.voucher_value_per_unit = null
        this.current.discount_value_per_unit = null
      } else {
        this.current.discount_type = null
      }
    }
  }
}
</script>
