<template>
  <th-wrapper
    v-loading="model.loading"
    class="subscription-card-calculation"
    :title="$t('pages.abocard_systems.edit.card_calculation.title')"
    :subtitle="
      $t('pages.abocard_systems.edit.card_calculation.static.subtitle')
    "
  >
    <el-form ref="form" :model="model" :rules="rules">
      <el-row :gutter="20">
        <!-- No. of individual vouchers -->
        <el-col :lg="5" :md="12">
          <el-form-item
            :label="$t('pages.abocard_systems.edit.card_calculation.units')"
            prop="units"
          >
            <el-input
              id="units"
              type="number"
              :model-value="model.units"
              :min="0"
              :controls="false"
              @update:modelValue="model.units = +$event"
            />
          </el-form-item>
        </el-col>

        <!-- Voucher value per unit -->
        <el-col :lg="5" :sm="24">
          <el-form-item
            :label="
              $t(
                'pages.abocard_systems.edit.card_calculation.voucher_value_per_unit'
              )
            "
            prop="voucher_value_per_unit"
          >
            <th-currency-input
              id="voucher_value_per_unit"
              :disabled="!canEdit"
              :model-value="model.voucher_value_per_unit"
              @update:modelValue="
                (value) => handleChange(value, 'onVoucherValuePerUnit')
              "
            />
          </el-form-item>
        </el-col>

        <!-- Discount per unit -->
        <el-col :lg="5" :sm="24">
          <el-form-item
            :label="
              $t(
                'pages.abocard_systems.edit.card_calculation.discount_value_per_unit'
              )
            "
            prop="discount_value_per_unit"
          >
            <th-currency-input
              id="discount_value_per_unit"
              :disabled="!canEdit"
              :model-value="model.discount_value_per_unit"
              @update:modelValue="
                (value) => handleChange(value, 'onDiscountValuePerUnit')
              "
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- Face value -->
        <el-col :lg="5" :sm="24">
          <el-form-item
            :label="
              $t('pages.abocard_systems.edit.card_calculation.total_value')
            "
            prop="total_value"
          >
            <th-currency-input
              id="total_value"
              :model-value="model.total_value"
              disabled
            />
          </el-form-item>
        </el-col>

        <!-- Selling price -->
        <el-col :lg="5" :sm="24">
          <el-form-item
            :label="
              $t('pages.abocard_systems.edit.card_calculation.selling_price')
            "
            prop="selling_price"
          >
            <th-currency-input
              id="selling_price"
              :disabled="!canEdit"
              :model-value="model.selling_price"
              @update:modelValue="
                (value) => handleChange(value, 'onSellingPrice')
              "
            />
          </el-form-item>
        </el-col>

        <!-- Discount total -->
        <el-col :lg="5" :sm="24">
          <el-form-item
            :label="
              $t(
                'pages.abocard_systems.edit.card_calculation.discount_value.total.label'
              )
            "
            prop="discount_value"
          >
            <th-currency-input
              id="discount_value"
              :disabled="!canEdit"
              :model-value="model.discount_value"
              @update:modelValue="
                (value) => handleChange(value, 'onDiscountValue')
              "
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import * as helpers from './helpers'
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    model: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    rules() {
      return {
        units: {
          required: true,
          message: this.$t('common.forms.rules.field_warnings.required'),
          trigger: ['blur', 'change']
        },
        voucher_value_per_unit: {
          required: true,
          message: this.$t('common.forms.rules.field_warnings.required'),
          trigger: 'blur'
        },
        selling_price: {
          required: true,
          message: this.$t('common.forms.rules.field_warnings.required'),
          trigger: 'blur'
        }
      }
    },
    canEdit() {
      return this.model.total_value > 0
    }
  },
  watch: {
    'model.units'() {
      this.updateCalculations()
    },
    'model.used_product_price'() {
      this.updateCalculations()
    }
  },
  mounted() {
    this.updateCalculations()
  },
  methods: {
    updateCalculations() {
      if (!Number.isFinite(this.model.used_product_price)) {
        return this.$emit('reset-abocard-calculation')
      }
      this.model.total_value =
        this.model.units >= 0
          ? this.model.units * this.model.used_product_price
          : 0

      this.handleChange(this.model.total_value, 'onTotalValue')
    },
    handleChange(value, type) {
      if (!helpers[type]) return
      const {
        selling_price,
        voucher_value_per_unit,
        discount_value_per_unit,
        discount_value
      } = helpers[type](this.model, value)
      this.model.selling_price = selling_price
      this.model.voucher_value_per_unit = voucher_value_per_unit
      this.model.discount_value_per_unit = discount_value_per_unit
      this.model.discount_value = discount_value
    },
    // ----------------- Validate -----------------
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
