<template>
  <th-wrapper
    v-loading="model.loading"
    :title="$t('pages.abocard_systems.edit.card_calculation.title')"
    :subtitle="
      $t('pages.abocard_systems.edit.card_calculation.dynamic.subtitle')
    "
    class="subscription-card-calculation-dynamic"
  >
    <el-form ref="form" :model="model" :rules="rules">
      <el-row :gutter="20">
        <!-- No. of individual vouchers -->
        <el-col :lg="5" :md="12">
          <el-form-item
            :label="$t('pages.abocard_systems.edit.card_calculation.units')"
            prop="units"
          >
            <el-input
              id="units"
              v-model="model.units"
              type="number"
              :min="0"
              :controls="false"
              @input="model.units = +$event"
            />
          </el-form-item>
        </el-col>
        <el-col :lg="3" :md="12">
          <!-- Discount Type -->
          <el-form-item
            prop="type"
            :label="
              $t(
                'pages.abocard_systems.edit.card_calculation.discount_type.label'
              )
            "
          >
            <el-radio-group
              id="type"
              v-model="model.discount_type"
              aria-labelledby="type"
            >
              <el-radio-button label="relative">
                {{
                  $t(
                    'pages.abocard_systems.edit.card_calculation.discount_value.percent'
                  )
                }}
              </el-radio-button>
              <el-radio-button label="absolute">
                {{ currentCurrencySymbol }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :lg="5" :md="12">
          <!-- Discount Value -->
          <el-form-item
            prop="discount_value"
            width="20px"
            :label="
              $t(
                'pages.abocard_systems.edit.card_calculation.discount_type.discount_value.label'
              )
            "
          >
            <th-number-input
              v-if="model.discount_type === 'relative'"
              id="discount_value"
              v-model="model.discount_value"
              append-text="%"
              class="w-full"
              :upper-limit="100.0"
              :locale="$i18n.locale"
            />
            <th-currency-input
              v-else
              id="discount_value"
              v-model="model.discount_value"
              class="w-full"
              clearable-is-left
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import { currencySymbol } from '@/constants'
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),
    model: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    rules() {
      return {
        units: {
          required: true,
          message: this.$t('common.forms.rules.field_warnings.required'),
          trigger: ['blur', 'change']
        },
        discount_value: {
          required: true,
          message: this.$t('common.forms.rules.field_warnings.required'),
          trigger: ['blur', 'change']
        }
      }
    },
    currentCurrencySymbol() {
      return currencySymbol[this.defaultCurrency]
    }
  },

  watch: {
    'model.discount_type'() {
      this.model.discount_value = null
    }
  },

  methods: {
    // ----------------- Validate -----------------
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
<style scoped>
.el-radio-group,
.el-radio-group :deep(.el-radio-button__inner) {
  width: 100%;
}
.el-radio-button {
  width: 50%;
}
</style>
