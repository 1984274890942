import baseModel from '@/models/base-model-new'

export default {
  setup(model) {
    const methods = {
      updateMethod() {
        return 'update'
      },
      resetAbocardCalculation() {
        this.current.selling_price = null
        this.current.voucher_value_per_unit = null
        this.current.discount_value_per_unit = null
        this.current.discount_value = null
        this.current.total_value = null
      }
    }
    return baseModel.setup(model, 'abocardSystems', methods)
  }
}
