<template>
  <th-page-wrapper
    :key="$route.fullPath"
    :actions="actions"
    @save="handleSubmit"
  >
    <abocard-form ref="form" class="p-6" />
  </th-page-wrapper>
</template>

<script>
import AbocardForm from './components/form.vue'

export default {
  components: {
    AbocardForm
  },

  computed: {
    isNew() {
      //model have a computed property that specify if the model is new or not
      return this.$refs.form?.form?.isNew
    },
    actions() {
      return {
        permissionPrefix: 'loyalty:abocard_systems',
        returnedPath: { name: 'abocard-systems-list' },
        excludeButtons: ['delete'],
        isNew: this.isNew
      }
    }
  },

  methods: {
    async handleSubmit() {
      await this.$refs.form.handleSubmit()
    },

    async handleDelete() {
      await this.$refs.form.handleDelete()
    }
  }
}
</script>
