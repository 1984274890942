<template>
  <el-select
    :id="id"
    v-model="localValue"
    class="w-full"
    :popper-append-to-body="false"
    :disabled="disabled"
    :placeholder="$t('common.interactions.buttons.select')"
    :clearable="clearable"
  >
    <el-option
      v-for="item in validityPeriodOptions"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Number,
      default: null
    },
    id: {
      type: String,
      default: 'validity_period'
    },
    years: {
      type: Array,
      default: () => [1, 2, 3]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    validityPeriodOptions() {
      return this.years.map((value) => {
        return {
          value,
          label: this.$t('common.forms.labels.validity_period', value)
        }
      })
    }
  }
}
</script>
